<!-- 商品区组件模板内容 -->
<template>
	<div>
		<!--商品列表-->
		<div v-if="item.url" class="goodsAreaTitle" :style='`background-image:url(${item.url})`'>悦享美食</div>
		<GoodsListWaterfall :goods_data_right="goods_data_right" :goods_data_left="goods_data_left"></GoodsListWaterfall>
	</div>
</template>
<script>
// import { findGoodsApi } from '@/api/index';
import GoodsListWaterfall from '@/components/GoodsListWaterfall';
import { getGoodsDataApi, getShopTypeApi } from '@/api/index'
import { _debounce } from '@/main'
export default {
	components: {
		GoodsListWaterfall
	},
	name: 'CommodityArea',
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		}
	},
	// 组件的data属性
	data() {
		return {
			isList: false,
			goods_data: [],
			searchWhere: 1,
			pageIndex: 1,
			goods_data_left: [],
			goods_data_right: []
		};
	},
	// 组件的方法
	methods: {
		getGoodsApi: _debounce(async function () { //获取商品列表
			let params = {
				comboVoucherId: localStorage.getItem('comboVoucherId'),
				// searchWhere: this.searchWhere,
				// title: '',
				page: this.pageIndex,
				pageSize: 10
			}
			let { data } = await getGoodsDataApi(params)
			console.log(data);
			if (data.length == 0) {
				this.isList = true;
				return false;
			}
			if (params.page == 1) {
				window.addEventListener('scroll', this.listenBottomOut)
			}
			this.goods_data = data
			this.pageIndex++
			for (let i = 0; i < this.goods_data.length; i++) {
				if (this.goods_data_left.length <= this.goods_data_right.length) {
					this.goods_data_left.push(this.goods_data[i])
				} else {
					this.goods_data_right.push(this.goods_data[i])
				}
			}
		}, 500),
		async listenBottomOut() {
			if (this.isList == false) {
				const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
				const clientHeight = document.documentElement.clientHeight
				const scrollHeight = document.documentElement.scrollHeight
				if (scrollTop + clientHeight >= scrollHeight - 1) {
					// 没有数据后，不触发请求
					await this.getGoodsApi()
				}
			}
		},
	},

	// 在created钩子中执行的操作
	created() {
		//首次查询商品
		// this.getGoodsApi()
	},
	// 移除滚动事件监听
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	}
};
</script>

<!-- 组件的样式 -->
<style scoped>
.goods_div {
	width: 100%;
	margin: 40px 0 10px;
}

.gass_like_title {
	font-size: 22px;
	font-weight: bold;
	color: #161616;
	text-align: center;
}

.goods_list_box {
	width: 94%;
	margin: 0px auto;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}

.goods_item {
	flex: 1;
	padding: 10px 10px;
	border-radius: 12px;
	margin: 5px 0;
	background-color: #fff;
	width: calc((100% - 10px) / 2);
	min-width: calc((100% - 50px) / 2);
	max-width: calc((100% - 50px) / 2);
}

.goods_img {
	width: 100%;
}

.goods_name {
	font-size: 15px;
	font-weight: bold;
	color: #333333;
	font-family: PingFang SC;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.goods_price_del {
	font-size: 16px;
	margin-top: 10px;
	color: #A5A5A5;
	text-decoration: line-through;
}

.goods_price {
	font-size: 17px;
	color: #333333;
	font-weight: bold;
}

.goods_loding {
	padding: 12px 0;
	text-align: center;
}

.goodsAreaTitle {
	width: 228px;
	height: 76px;
	margin: 12px auto;
	font-size: 20px;
	font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
	font-weight: 400;
	color: #FFD67B;
	line-height: 96px;
	text-align: center;
	padding-left: 40px;
}
</style>