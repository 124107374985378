import service from "@/utils/request"

//商品列表查询
export function getGoodsDataApi(params) {
	return service({
		url: 'h5/region/getPartitionGoods',
		method: 'get',
		params, isShow: true
	})
}
//region礼包详情查询
export function getGiftBagDetailApi(params) {
	return service({
		url: 'h5/region/getComboGiftInfo',
		method: 'get',
		params, isShow: true
	})
}
//cardPackage礼包详情查询
export function getGiftBagDetailApi2(params) {
	return service({
		url: 'h5/cardPackage/getCardPackageList',
		method: 'get',
		params, isShow: true
	})
}

//查询用户卡包列表
export function getCardBagListApi(params) {
	return service({
		url: 'h5/cardPackage/getAllCardPackageList',
		method: 'get',
		params, isShow: true
	})
}
//查询用户分区兑换次数
export function getUserInfoApi(params) {
	return service({
		url: 'h5/region/getCardState',
		method: 'get',
		params,login:false
	})
}


//商品详情查询
export function getGoodsDetailApi(params) {
	return service({
		url: 'h5/region/getComboGiftGoodsInfo',
		method: 'get',
		params, isShow: true
	})
}
//查询订单
export function getMyOrderApi(params) {
	return service({
		url: '/h5/order/getOrderList',
		method: 'get',
		params, isShow: true
	})
}

//获取验证码
export function sendCodeApi(params) {
	return service({
		url: '/user/sendCode',
		method: 'post',
		data: params, isShow: true
	})
}
//登录方法--扫码登录
export function loginByCode(params) {
	return service({
		url: '/ecard-card-item/login',
		method: 'post',
		data: params, isShow: true
	})
}
//登录方法
export function loginApi(params) {
	return service({
		url: '/ecard-card-user/login',
		method: 'post',
		data: params, isShow: true
	})
}
//登录方法--直接登录
export function userloginApi(params) {
	return service({
		url: '/user/login',
		method: 'post',
		data: params, isShow: true
	})
}
//存入卡包
export function bindCardBagApi(params) {
	return service({
		url: '/ecard-card-item/bind',
		method: 'post',
		data: params, isShow: true
	})
}


//查询省区地址
export function getProCityAreaApi(params) {
	return service({
		url: '/jdregion/findJdregion',
		method: 'get',
		params, isShow: true
	})
}


//创建送货地址
export function addAddressApi(params) {
	return service({
		url: '/jdregion/createUserAddress',
		method: 'post',
		data: params, isShow: true
	})
}

//获取地址列表
export function getAddressListApi(params) {
	return service({
		url: '/jdregion/getUserAddressList',
		method: 'get',
		params, isShow: true,login:false
	})
}
//获取默认地址
export function getAddressDefaultApi(params) {
	return service({
		url: '/jdregion/getDefaultAddress',
		method: 'get',
		params, 
		isShow: true,
		login:false
	})
}

//删除地址
export function removeAddressApi(params) {
	return service({
		url: '/jdregion/deleteUserAddress',
		method: 'delete',
		params, isShow: true
	})
}

//获取地址详情
export function getAddressDetailApi(params) {
	return service({
		url: '/jdregion/getUserAddress',
		method: 'get',
		params, isShow: true
	})
}

//修改送货地址
export function editAddressApi(params) {
	return service({
		url: '/jdregion/updateUserAddress',
		method: 'post',
		data: params, isShow: true
	})
}

//验证卡
export function verifyCardApi(params) {
	return service({
		url: '/ecard-card-item/check',
		method: 'get',
		params, isShow: true
	})
}

//下单
export function createOrderApi(params) {
	return service({
		url: '/h5/order/create',
		method: 'post',
		data: params, isShow: true
	})
}
export function createCardItemOrderApi(params) {
	return service({
		url: '/h5/order/createCardItemOrder',
		method: 'post',
		data: params, isShow: true
	})
}


//导入用户下单
export function createItemOrderApi(params) {
	return service({
		url: '/h5/order/createItemOrder',
		method: 'post',
		data: params, isShow: true
	})
}

//物流信息查询
export function getlogisticsApi(params) {
	return service({
		url: '/h5/order/delivery',
		method: 'get',
		params
	})
}
//上传图片
export function uploadImgApi(params) {
	return service({
		url: '/upload/img',
		method: 'post',
		data:params
	})
}
//提交售后
export function submitAfterSaleApi(params) {
	return service({
		url: '/h5/afterSales',
		method: 'post',
		data:params
	})
}
//查询所有售后
export function getAfterSalesDataApi(params) {
	return service({
		url: '/h5/afterSales',
		method: 'get',
		params
	})
}
//取消售后
export function cancelAfterSaleApi(params) {
	return service({
		url: '/h5/afterSales/updateAfterSale',
		method: 'post',
		data:params
	})
}