import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import User from '../views/User.vue'
import CardBag from '../views/CardBag.vue'
import GoodsSpecies from '../views/GoodsSpecies.vue'
Vue.use(VueRouter)


const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			index: 0,
			keepAlive: true,//是否缓存
			showTabbar: true //是否显示tabbar
		}
	},
	{
		path: '/cardBag',
		name: 'CardBag',
		component: CardBag,
		meta: {
			index: 1,
			showTabbar: true //是否显示tabbar
		}
	},
	{
		path: '/user',
		name: 'User',
		component: User,
		meta: {
			index: 2,
			showTabbar: true //是否显示tabbar
		}
	},
	{
		path: '/goodsSpecies',
		name: 'GoodsSpecies',
		component: GoodsSpecies,
		meta: {
			keepAlive: true,//是否缓存
		}
	},
	{
		path: '/giftBagDetail',
		name: 'GiftBagDetail',
		component: () => import('../views/GiftBagDetail.vue'),
	},
	{
		path: '/giftBagDetailWithcode',
		name: 'GiftBagDetailWithcode',
		component: () => import('../views/GiftBagDetailWithcode.vue'),
	},
	{
		path: '/goodsDetail/:id?',
		name: 'GoodsDetail',
		component: () => import('../views/GoodsDetail.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue'),
	},
	{
		path: '/addressList',
		name: 'AddressList',
		component: () => import('../views/Address/AddressList.vue')
	},
	{
		path: '/editAddress',
		name: 'EditAddress',
		component: () => import('../views/Address/EditAddress.vue')
	},
	{
		path: '/orderDetail',
		name: 'OrderDetail',
		component: () => import('../views/OrderDetail.vue')
	},
	{
		path: '/orderRecord',
		name: 'OrderRecord',
		component: () => import('../views/Record/OrderRecord.vue')
	},
	{
		path: '/logistics',
		name: 'Logistics',
		component: () => import('../views/Logistics.vue')
	},
	{
		path: '/choseAftersaleType',
		name: 'ChoseAftersaleType',
		component: () => import('../views/AfterSale/ChoseAftersaleType.vue')
	},
	{
		path: '/submitAfterSale',
		name: 'SubmitAfterSale',
		component: () => import('../views/AfterSale/SubmitAfterSale.vue')
	},
	{
		path: '/afterSaleList',
		name: 'AfterSaleList',
		component: () => import('../views/AfterSale/AfterSaleList.vue')
	}
]



const router = new VueRouter({
	mode: "history",
	routes
})

// router.beforeEach((to, from, next) => {
// 	// 如果是去个人中心页，要确认本地存储是否有token
// 	if (to.path != '/login') {
// 		if (!localStorage.getItem("userToken")) {
// 			//没有token就去往登录页
// 			next({
// 				name: 'Login',
// 				query: { comboVoucherId: to.query.comboVoucherId }
// 			});
// 		} else {
// 			//没有token就去往登录页
// 			next()
// 		}
// 	}
// 	next()
// });

export default router
