import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		cardStatus: -1,
		cardSecret: '',
		cardNo: '',
	},
	getters: {
	},
	mutations: {
		updateStatus(state, data) {
			state.cardStatus = data
		},
		updateCardSecret(state, data) {
			state.cardSecret = data
		},
		updateCardNo(state, data) {
			state.cardNo = data
		},
	},
	actions: {
	},
})
