<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" />
		<van-tabbar route active-color="#FF7814" placeholder v-show="$route.meta.showTabbar" fixed>
			<van-tabbar-item replace to="/" icon="wap-home-o">首页</van-tabbar-item>
			<van-tabbar-item replace to="/cardBag" icon="balance-pay">卡包</van-tabbar-item>
			<van-tabbar-item replace to="/user" icon="user">我的</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<style>
* {
	padding: 0;
	margin: 0;
	/*font-family: "Microsoft YaHei", "Heiti SC", tahoma, arial, "Hiragino Sans GB", 宋体, sans-serif;*/
	font-family: -apple-system, Helvetica, sans-serif;
}

div {
	background-position: center;
	background-size: 100% 100%;
	box-sizing: border-box;
}

.van-tabbar {
	padding-bottom: 10px !important;
}

.flex_sb {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex_no {
	display: flex;
	align-items: center;
}

html,
body {
	background: #eff0f0;
}

.van-nav-bar .van-icon {
	color: #333 !important;
}

.van-nav-bar__text {
	color: #333 !important;
}

.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.block {
	background-color: #fff;

}

.twoLineBox {
	width: 100%;
	overflow: hidden;
	white-space: normal;
	word-break: break-all;
	word-wrap: break-word;
	display: table-cell;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: 13px;
	color: #666;
}

.toast_title {
	text-align: center;
	font-size: 14px;
	color: #999;
}

.toast_title img {
	width: 30%;
	padding: 1px;
	aspect-ratio: 1/1;
	margin: 20px 0;
}
</style>

<script>
import { getComboVoucherInfoApi } from "@/api";

export default {
	name: "APP",
	data() {
		return {

		}
	},
	methods: {
		// async getComboVoucherInfo() {
		//   let { data }=await getComboVoucherInfoApi({
		//     comboVoucherId:localStorage.getItem("comboVoucherId")
		//   });
		//   document.title=data.title || "三江智采";
		// },
	},
	created() {
		// this.getComboVoucherInfo();
	}
}
</script>