<template>
	<div class="search">
		<div class="exchangeNum">
			可兑换<span style="color:#D10000;font-weight:600;">{{ userInfo.numberOfUse?userInfo.numberOfUse:0 }}</span>次
		</div>
		<div class="searchBox">
			<input class="searchInput" type="text" placeholder="搜想要的商品" id="keyWord" />
			<div class="searchBtn">搜索</div>
		</div>
	</div>
</template>

<script>
import { getUserInfoApi } from '@/api/index'
export default {
	name: 'headerSearch',
	components: {

	},
	props: {
	},
	data() {
		return {
			userInfo:{}
		}
	},
	methods: {
		async getUserInfo() {
			let { data } = await getUserInfoApi({
				comboVoucherId: localStorage.getItem('comboVoucherId')
			})
			this.userInfo = data
		},
		
	},
	created(){
		this.getUserInfo()
	},
	activated(){
		this.getUserInfo()
	}
}

</script>
<style scoped lang="less">
.search {
	width: 94.6%;
	margin: 12px auto;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&>div {
		display: flex;
		align-items: center;
	}

	.searchBox {
		width: 100%;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 12px;
		background: #fff;
		padding-left: 12px;
		padding-right: 4px;
		box-sizing: border-box;

		.searchInput {
			outline: 0;
			border: 0;
			height: 30px;
			line-height: 30px;
			font-size: 14px;
		}

		.searchBtn {
			width: 50px;
			height: 24px;
			background: #D10000;
			border-radius: 12px;
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 24px;
			text-align: center;
		}
	}
}

.exchangeNum {
	height: 30px;
	background: #fff;
	margin-right: 10px;
	font-size: 14px;
	white-space: nowrap;
	padding: 0 12px;
	border-radius: 12px;
}
</style>