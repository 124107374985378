<!-- 首页 -->
<template>
	<div id='home'>
		<div class="headerBox">
			<div class="logo">
				<img src="@/assets/images/index/logo.png" alt="">
				<div class="logoDesc">全场京东</div>
				<div class="logoDesc">自营正品</div>
			</div>
			<div class="login" @click="$router.push('/login')">
				<van-icon name="user-circle-o" size="24" />
				<div style="font-size:14px;">登录</div>
			</div>
		</div>
		<div class="prefectureCard" style="position:relative;margin-top:-60px;">
			<div class="prefectureCardItem" v-for="(item, index) in prefectureData" :key="index">
				<template v-if="item.goods.length > 0">
					<div class="prefectureCardImage flex_sb" :style="`background:${item.bgColor}`">
						<div :style="`color:${item.color};`">
							<span style="font-size: 20px;font-weight:600;">{{ item.title }}</span>
							<span style="font-size: 13px;">&nbsp;&nbsp;&nbsp;该品类可选{{ item.choseNum }}件</span>
						</div>
						<div class="more_btn"
							@click="$router.push({ path: '/GoodsSpecies', query: { title: item.title, region: index + 1 } })">
							查看更多 ></div>
					</div>
					<div class="prefectureGoodsBox">
						<div class="prefetureGoodsItem" v-for="(it, i) in item.goods" :key="i"
							@click="$router.push({ path: '/giftBagDetail', query: { id: it.goodsId } })">
							<img :src="it.imageUrl" alt="">
							<div class="prefectureGoodsTitle">{{ it.title }}</div>
						</div>
					</div>
				</template>
			</div>
		</div>
		<!-- <HomeComponents v-for="(item, index) in componentList" :key="index" :item="item"></HomeComponents> -->
		<div style="height:12px;"></div>
	</div>
</template>
<style>
.logo {
	width: 118px;
	height: 46px;
	position: absolute;
	left: 10px;
	top: 10px;
}

.logo img {
	width: 118px;
	height: 26px;
}

.logoDesc {
	font-size: 11px;
	font-weight: 600;
	color: #3c4261;
	letter-spacing: 3px;
	margin-left: 4px;
	margin-top: -2px;
}

.login {
	position: absolute;
	right: 10px;
	top: 10px;
	height: 46px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: #333;
}
</style>
<style scoped>
.headerBox {
	width: 100%;
	aspect-ratio: 750 / 800;
	padding: 1px;
	background-image: url('@/assets/images/index/banner.png');
	position: relative;
}

.prefectureCardItem {
	width: 94%;
	margin: 12px auto;
}

.prefectureCardImage {
	width: 100%;
	aspect-ratio: 710 / 80;
	border-radius: 14px 14px 0px 0px;
	background-position: center;
	background-size: 100% 100%;
	box-sizing: border-box;
	padding: 0 15px;
}

.more_btn {
	width: 80px;
	height: 22px;
	background: #ffffff;
	border-radius: 20px;
	font-size: 12px;
	text-align: center;
	line-height: 22px;
	opacity: .6;
}

.prefectureGoodsBox {
	display: flex;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	background-color: #fff;
	padding: 10px;
	box-sizing: border-box;
}

.prefectureGoodsBox::-webkit-scrollbar {
	display: none;
}

.prefetureGoodsItem {
	width: 23% !important;
	margin-right: 10px;
	flex-shrink: 0;
	font-size: 15px;
}

.prefetureGoodsItem img {
	width: 100%;
	aspect-ratio: 1 / 1;
}

.prefectureGoodsTitle {
	width: 100%;
	overflow: hidden;
	white-space: normal;
	word-break: break-all;
	word-wrap: break-word;
	display: table-cell;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: 13px;
	color: #666;
	margin: 6px 0;
}
</style>

<script>
import HomeComponents from "@/components/HomeComponents";
import { getGoodsDataApi, getUserInfoApi,  } from '../api/index'
import { Dialog, Toast } from 'vant';
export default {

	//import引入的组件需要注入到对象中才能使用
	components: { HomeComponents },
	data() {
		//这里存放数据
		return {
			componentList: [],
			prefectureData: [{
				title: '时令新品',
				color: '#ffe990',
				bgColor: '#bf5037',
				choseNum: 0,
				goods: []
			}, {
				title: '良品优选',
				color: '#bf5037',
				bgColor: '#ffe990',
				choseNum: 0,
				goods: []
			},
			{
				title: '优品好物',
				color: '#fffdda',
				bgColor: '#5c5caa',
				choseNum: 0,
				goods: []
			},
			{
				title: '精品好礼',
				color: '#edffda',
				bgColor: '#5eaa5c',
				choseNum: 0,
				goods: []
			},
			{
				title: '臻品特典',
				color: '#fff2da',
				bgColor: '#9434d0',
				choseNum: 0,
				goods: []
			}],
		};
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {},
	//方法集合
	methods: {
		async getGoodsData() {
			let params = {
				size: '',
				current: '',
				cardId: localStorage.getItem('cardId') ? localStorage.getItem('cardId') : this.$route.query.cardId,
				region: ''
			}
			let { data } = await getGoodsDataApi(params)
			for (let i = 0; i < 5; i++) {
				this.prefectureData[i].goods = data.rows[i + 1]
				console.log(this.prefectureData[i]);
				console.log(data.rows[i + 1]);
			}
			this.getExchangeNum()
		},
		async getExchangeNum() {
			let { data } = await getUserInfoApi({
				cardId: localStorage.getItem('cardId')
			})
			this.prefectureData[0].choseNum = data.regionOneNum
			this.prefectureData[1].choseNum = data.regionTwoNum
			this.prefectureData[2].choseNum = data.regionThreeNum
			this.prefectureData[3].choseNum = data.regionFourNum
			this.prefectureData[4].choseNum = data.regionFiveNum
		},
	},
	beforeCreate() { },
	created() {
		if (this.$route.query.cardId == undefined && !localStorage.getItem('cardId')) {
			alert('礼包不存在或已过期')
			return false
		}
		else if (this.$route.query.cardId != undefined) {
			if (localStorage.getItem('cardId') && localStorage.getItem('cardId') != this.$route.query.cardId) {
				localStorage.removeItem('cardId')
				localStorage.removeItem('userToken')
				localStorage.removeItem('addressData')
				localStorage.removeItem('selectAddressData')
				localStorage.setItem('cardId', this.$route.query.cardId)
			}
			localStorage.setItem('cardId', this.$route.query.cardId)
		}
		document.title = '一采一购'
		this.getGoodsData()
	},
	beforeMount() { },
	mounted() {

	},
	beforeUpdate() { },
	updated() { },
	beforeDestroy() { },
	destroyed() { },
	activated() { this.getExchangeNum() },
}
</script>
