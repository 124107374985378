<template>
	<div id='cardBag'>
		<img class="headerBanner" src="@/assets/images/cardBag/bj2.png" alt="">
		<div class="contentBox">
			<van-tabs v-model="active" color="#FD993B" background="#eff0f0" title-active-color="#FD993B"
				@click="changeTabs">
				<van-tab title="全部"></van-tab>
				<van-tab title="已提货"></van-tab>
				<van-tab title="未提货"></van-tab>
			</van-tabs>
			<div class="cardBagList">
				<div class="cardBagItem" v-for="(item, index) in cardBagList" :key="index">
					<div class="flex_sb" style="font-size: 14px;color:#999;">
						<div>到期时间:{{ item.endTime }}</div>
						<div v-if="item.status == 2">已提货</div>
						<div v-if="item.status == 4">未提货</div>
					</div>
					<div class="flex_no" style="margin-top:10px;align-items:start;">
						<img :src="item.imageUrl" alt="">
						<div class="twoLineBox" style="margin-left:10px;width:50%;font-size:16px;">{{ item.name }}</div>
					</div>
					<div class="flex_sb" v-if="item.status != 2">
						<div></div>
						<div class="signBtn" @click="$router.push({
				path: '/giftBagDetailWithcode',
				query: { cardId: item.cardId, comboId: item.comboId, cardStatus: item.status, cardNo: item.cardNo }
			})">立即提货</div>
					</div>
				</div>
			</div>
			<template v-if="cardBagList.length == 0">
				<div class="toast_title"> <!--当商品数据为空时-->
					<img src="https://img.yzcdn.cn/vant/custom-empty-image.png" alt="">
					<div>卡包空空如也</div>
				</div>
			</template>
		</div>
	</div>
</template>
<style scoped>
.headerBanner {
	display: block;
	width: 100%;
}

.contentBox {
	background-color: #eff0f0;
	width: 100%;
	margin-top: -60px;
	position: relative;
	border-radius: 18px 18px 0px 0px;
	padding: 15px 0;
}

.cardBagItem {
	width: 94.6%;
	margin: 15px auto;
	background: #fff;
	padding: 15px 10px;
	border-radius: 10px;
}

.cardBagItem img {
	width: 120px;
	height: 80px;
}

.signBtn {
	width: 90px;
	height: 28px;
	background: #FD993B;
	text-align: center;
	line-height: 28px;
	font-size: 14px;
	color: #fff;
	border-radius: 20px;
}
</style>
<script>
import { getCardBagListApi } from '../api/index'
export default {
	//import引入的组件需要注入到对象中才能使用
	components: {},
	data() {
		//这里存放数据
		return {
			active: 0,
			cardBagList: []
		};
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {},
	//方法集合
	methods: {
		async getCardBagList() {
			let params = {
				status: this.active == 0 ? '' : this.active == 1 ? 2 : 4
			}
			let { data } = await getCardBagListApi(params)
			this.cardBagList = data
		},
		changeTabs() {
			this.getCardBagList()
		}
	},
	beforeCreate() { }, //生命周期 - 创建之前
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		this.getCardBagList()
	},
	beforeMount() { }, //生命周期 - 挂载之前
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {

	},
	beforeUpdate() { }, //生命周期 - 更新之前
	updated() { }, //生命周期 - 更新之后
	beforeDestroy() { }, //生命周期 - 销毁之前
	destroyed() { }, //生命周期 - 销毁完成
	activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>