<!-- 组件模板内容 -->
<template>
	<div>
		<BackGroundImages v-if="item.name == 'header-backgroundAndSearch'" :item="item"></BackGroundImages>
		<JinGang v-if="item.name == 'jinGang'" :item="item" style="margin:10px 0;"></JinGang>
		<BannerArea v-if="item.name == 'active-banner'" :item="item"></BannerArea>
		<PorcelainTileArea v-if="item.name == 'porcelainTileArea'" :item="item"></PorcelainTileArea>
		<CommodityArea v-if="item.name == 'waterfallArea'" :item="item"></CommodityArea>
		<SwiperArea v-if="item.name == 'swiperArea'" :item="item"></SwiperArea>
		<headerBanner v-if="item.name == 'header-banner'" :item="item"></headerBanner>
		<headerSearch v-if="item.name == 'header-search'" :item="item"></headerSearch>
		<!-- <van-tabs v-if="item.name == 'categoryArea'" sticky style="width:100%;margin:12px 0;" @click="cateChange"
			v-model="active">
			<van-tab v-for="(item, index) in cateData" :key="index" :title="item.fullName">
			</van-tab>
		</van-tabs> -->
	</div>
</template>

<script>
import BackGroundImages from "./components/BackGroundImages";
import JinGang from "./components/JinGang";
import PorcelainTileArea from "./components/PorcelainTileArea";
import CommodityArea from "./components/CommodityArea";
import SwiperArea from "./components/SwiperArea";
import BannerArea from "./components/BannerArea";
import headerBanner from "./components/headerBanner";
import headerSearch from "./components/headerSearch";
export default {
	name: "HomeComponents",
	components: {
		BackGroundImages,
		JinGang,
		PorcelainTileArea,
		CommodityArea,
		SwiperArea,
		BannerArea,
		headerBanner,
		headerSearch
	},
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		},
	},
	// 组件的data属性
	data() {
		return {
			active: 0,
			cateData: [{
				fullName: '食品饮料'
			}, {
				fullName: '个人护理'
			}, {
				fullName: '家用电器'
			}, {
				fullName: '数码'
			}, {
				fullName: '家纺'
			}]
		};
	},
	// 组件的方法
	methods: {
		cateChange() {

		},

	},
	// 在created钩子中执行的操作
	created() {

	},
};
</script>

<!-- 组件的样式 -->
<style scoped></style>