<!-- 商品区组件模板内容 -->
<template>
	<div class="content">
		<img :src="item.url" alt="">
		<!--商品列表-->
	</div>
</template>
<script>
export default {
	name: 'headerBanner',
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		}
	},
	// 组件的data属性
	data() {
		return {

		};
	},
	// 组件的方法
	methods: {

	},
};
</script>

<!-- 组件的样式 -->
<style scoped>
.content img{
	width: 94%;
	aspect-ratio: 350/200;
	padding: 1px;
	box-sizing: border-box;
	display: block;
	margin: 0 auto;
}
</style>