<!--  -->
<template>
	<div id='goodsSpecies'>
		<van-nav-bar placeholder fixed :title="$route.query.title" left-arrow @click-left="$router.back()" />
		<GoodsListWaterfall :goods_data_right="goods_data_right" :goods_data_left="goods_data_left"></GoodsListWaterfall>
	</div>
</template>

<script>
import { getGoodsDataApi } from '../api/index'
import GoodsListWaterfall from '../components/GoodsListWaterfall/index.vue'
import { _debounce } from '@/main';
export default {
	//import引入的组件需要注入到对象中才能使用
	components: { GoodsListWaterfall },
	data() {
		//这里存放数据
		return {
			current: 1,
			region: 1,
			goods_data_left: [],
			goods_data_right: [],
		};
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {},
	//方法集合
	methods: {
		getGoodsData: _debounce(function () {
			let params = {
				size: 10,
				current: this.current,
				cardId: localStorage.getItem('cardId'),
				region: this.$route.query.region
			}
			getGoodsDataApi(params).then(res => {
				console.log(res);
				if (params.current == 1) {
					window.addEventListener('scroll', this.listenBottomOut)
				}
				for (let i = 0; i < res.data.rows.length; i++) {
					if (this.goods_data_left.length <= this.goods_data_right.length) {
						this.goods_data_left.push(res.data.rows[i])
					} else {
						this.goods_data_right.push(res.data.rows[i])
					}
				}
				this.current++
			})
		}, 500),
		listenBottomOut() {
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 获取页面的可视高度
			const clientHeight = document.documentElement.clientHeight; // 获取页面的总高度
			const scrollHeight = document.documentElement.scrollHeight; // 判断是否滚动到底部，并且不在加载中
			if (scrollTop + clientHeight >= scrollHeight - 1) {
				// 没有数据后，不触发请求
				this.getGoodsData()
			}
		},
	},
	beforeCreate() { }, //生命周期 - 创建之前
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		this.region = this.$route.query.region
		this.getGoodsData()
	},
	beforeMount() { }, //生命周期 - 挂载之前
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {

	},
	beforeUpdate() { }, //生命周期 - 更新之前
	updated() { }, //生命周期 - 更新之后
	beforeDestroy() { }, //生命周期 - 销毁之前
	destroyed() { }, //生命周期 - 销毁完成
	activated() {
		console.log(this.region);
		console.log(this.$route.query.region);
		if (this.region != this.$route.query.region) {
			this.current = 1
			this.region = this.$route.query.region
			this.goods_data_left = []
			this.goods_data_right = []
			this.getGoodsData()
		}
	}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style></style>