import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import { Toast, Dialog } from 'vant'
// create an axios instance
const service = axios.create({
	baseURL: '/api',
	timeout: 50000 // request timeout
})
service.interceptors.request.use(
	config => {
		if (config.method === 'post' && config.data instanceof FormData) {
			config.headers['Content-Type'] = 'multipart/form-data';
		} else {
			config.headers['Content-Type'] = 'application/json;charset=UTF-8';
		}
		if (config.isShow == true) {
			Toast.loading({
				message: '加载中...',
				forbidClick: true,
			});
		}
		if (localStorage.getItem("userToken")) {
			config.headers['token'] = localStorage.getItem("userToken");
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)



service.interceptors.response.use(
	response => {
		Toast.clear()
		const res = response.data
		if (res.code == undefined) {
			return res
		}
		if (res.code == -1) {
			Dialog.confirm({
				title: '提示',
				message: '用户未登录,是否前往登录',
			}).then(() => {
				router.push('/login')
			}).catch(() => {
				router.push('/')
			})
		}
		if (res.code !== 200) {
			return Promise.reject(res)
		} else {
			return res;
		}
	},
	error => {
		Toast.clear()
		console.log(error);
		let data = error.response.data
		console.log(data);
		if (error.config.login!=false) {
			if (data.code == -1) {
				Dialog.confirm({
					title: '提示',
					message: '用户未登录,是否前往登录',
				}).then(() => {
					router.push('/login')
				}).catch(() => {
					router.push('/')
				})
			}
		}
		return Promise.reject(error)
	}
)
export default service
