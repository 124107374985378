<template>
	<div id="user">
		<div class="headerBox">
			<div class="userMsgBox">
				<div class="flex_no">
					<div>
						<div class="userName">{{ phone }}</div>
						<!-- <div class="editUserMsg" @click="userShow = !userShow">编辑个人资料</div> -->
					</div>
				</div>
				<div class="flex_no" style="color:#fff;">
					<div class="flex_no" style="flex-direction: column;margin-right:14px;"
						@click="$router.push('/addressList')">
						<van-icon name="location-o" size="28" />
						<div style="font-size:13px;margin-top:4px;">地址管理</div>
					</div>
					<div class="flex_no" style="flex-direction: column;" @click="showKefu">
						<van-icon name="service-o" size="28" />
						<div style="font-size:13px;margin-top:4px;">联系客服</div>
					</div>
				</div>
			</div>
			<div class="orderTypeBox">
				<div class="orderTypeTitle">
					<div style="color: #333;font-size: 15px;font-weight: 600;">我的订单</div>
				</div>
				<div class="orderTypeList">
					<div class="orderTypeItem" @click="$router.push({ path: '/orderRecord', query: { active: 0 } })">
						<img src="../assets/images/user/daifahuo.png" />
						<!-- <div class="goodsNum">0</div> -->
						<div>待发货</div>
					</div>
					<div class="orderTypeItem" @click="$router.push({ path: '/orderRecord', query: { active: 1 } })">
						<img src="../assets/images/user/yifahuo.png" />
						<!-- <div class="goodsNum">0</div> -->
						<div>待收货</div>
					</div>
					<div class="orderTypeItem" @click="$router.push({ path: '/orderRecord', query: { active: 2 } })">
						<img src="../assets/images/user/yiwancheng.png" />
						<!-- <div class="goodsNum" wx:if="{{finished&&finished!=0}}">{{finished}}</div> -->
						<div>已完成</div>
					</div>
					<div class="orderTypeItem" @click="$router.push({ path: '/afterSaleList'})">
						<img src="../assets/images/user/tuikuan.png" style="width: 36px;" />
						<!-- <div class="goodsNum" wx:if="{{finished&&finished!=0}}">{{finished}}</div> -->
						<div>退换/售后</div>
					</div>
				</div>
			</div>
		</div>

		<!--联系客服弹窗-->
		<van-action-sheet v-model="kefushow" title="客服联系方式">
			<div class="block" @click.stop>
				<div style="text-align:center;padding:20px;" @click="callPhone('4000662158')">4000662158(点击拨号)</div>
			</div>
		</van-action-sheet>

		<!--修改个人信息弹窗-->
		<van-action-sheet v-model="userShow" title="修改个人信息">
			<van-field name="uploader" label="头像">
				<template #input>
					<van-uploader ref="uploader" v-model="fileList" :after-read="afterRead" multiple :max-count="1" />
				</template>
			</van-field>
			<van-field v-model="userName" label="用户名" placeholder="请输入用户名" />
			<div style="display:flex;width:94.6%;margin: 20px auto;">
				<div class="editUserInfoBtn" style="background: #999;margin-right:10px;" @click="removeUserInfo">退出登录</div>
				<div class="editUserInfoBtn" @click="editUserInfoApi">保存</div>
			</div>
		</van-action-sheet>
	</div>
</template>
<style scoped>
.block {
	width: 100%;
	border-radius: 10px;
	padding: 10px;
	background-color: #fff;
}

.headerBox {
	width: 100%;
	aspect-ratio: 750 / 470;
	background-image: url('@/assets/images/user/bj.png');
	background-size: 100% 100%;
	background-position: center;
	padding: 12px;
	position: relative;
	/* position: relative; */
}

.userMsgBox {
	width: 94%;
	padding: 0 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	bottom: 70px;
}

.userName {
	font-size: 16px;
	font-family: PingFang SC;
	color: #333333;
}

.orderTypeBox {
	width: 94.6%;
	/* height: 140px; */
	padding: 10px 0px;
	background-color: #fff;
	border-radius: 14px;
	margin: 0 auto;
	margin-top: 5px;
	margin-bottom: 10px;
	position: absolute;
	bottom: -80px;
}

.orderTypeTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 600;
	color: #666;
	padding: 5px 20px;
	margin-bottom: 10px;
}

.orderTypeList {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.orderTypeItem {
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 13px;
	justify-content: space-between;
	color: #666;
}

.orderTypeItem img {
	width: 30px;
	height: 30px;
	margin-bottom: 6px;
}

.goodsNum {
	position: absolute;
	background-color: #f00;
	width: 17px;
	height: 17px;
	font-size: 14px;
	color: #fff;
	text-align: center;
	line-height: 17px;
	border-radius: 50%;
	right: -6px;
	top: -6px;
}
.userName {
	font-size: 18px;
	color: #fff;
	font-weight: 600;
	margin-bottom: 6px;
}

.editUserMsg {
	padding: 1px 8px;
	text-align: center;
	background: #fff;
	border-radius: 10px;
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	color: #666;
}
</style>
<script>
import GoodsListWaterfall from '@/components/GoodsListWaterfall';
import { getUserInfoApi } from '@/api/index'
import { Toast, Dialog } from 'vant';

export default {
	//import引入的组件需要注入到对象中才能使用
	components: {
		GoodsListWaterfall,
	},
	data() {
		//这里存放数据
		return {
			goods_data_left: [],
			goods_data_right: [],
			kefushow: false,
			userShow: false,
			userInfo: {},
			userName: '',
			avatar: '',
			fileList: [],
			shopInfo: {},
			phone:'未登录'
		};
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {},
	//方法集合
	methods: {
		logout() {
			Dialog.confirm({
				title: '提示',
				message: '是否确认退出登录?',
			}).then(() => {
				Toast.success('退出成功');
				setTimeout(() => {
					localStorage.removeItem('userToken')
					this.userInfo = {}
					this.$router.push({ path: '/login', params: { comboVoucherId: localStorage.getItem('comboVoucherId') } })
				}, 1000)
			})
		},
		afterRead(file) {
			console.log(file);
			// 此时可以自行将文件上传至服务器
			this.avatar = this.base64ToImage(file.content);
		},
		showKefu() {
			this.kefushow = !this.kefushow
		},
		async getUserInfo() {
			let { data } = await getUserInfoApi({
				cardId: localStorage.getItem('cardId')
			})
			this.userInfo = data
		},
		//修改用户信息方法
		editUserInfoApi() {

		},
		removeUserInfo() {
			Dialog.confirm({
				title: '提示',
				message: '是否确认退出登录?',
			}).then(() => {
				localStorage.removeItem('userToken')
				localStorage.removeItem('addressData')
				this.userInfo = {}
				this.$router.push('/')
			})
		},
		callPhone(phone) {
			window.location.href = 'tel://' + phone
		}

	},
	beforeCreate() {
	}, //生命周期 - 创建之前
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		// if (this.$route.query.showKefu && localStorage.getItem('userToken')) {
		// 	this.kefushow = true
		// }
		// this.shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
		if(localStorage.getItem('phone')){
			this.phone = localStorage.getItem('phone')
		}
		this.getUserInfo()
	},
	beforeMount() {
	}, //生命周期 - 挂载之前
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {

	},
	beforeUpdate() {
	}, //生命周期 - 更新之前
	updated() {
	}, //生命周期 - 更新之后
	beforeDestroy() {
	}, //生命周期 - 销毁之前
	destroyed() {
	}, //生命周期 - 销毁完成
	activated() {
	}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style></style>