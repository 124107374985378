<!-- 组件模板内容 轮播图-->
<template>
	<div class="carousel">
		<!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item v-for="(item, index) in image_list" :key="index" :item="item">
				<img style="width:100%;height:100%;" :src="item.src" alt="">
			</van-swipe-item>
		</van-swipe> -->
	</div>
</template>

<script>
export default {
	name: 'SwiperArea',
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		}
	},
	// 组件的data属性
	data() {
		return {
			image_list: [ //图片列表
				{ src: 'https://yigsj.oss-cn-hangzhou.aliyuncs.com/sj/imageIndexAction/H5_03.png' },
				{ src: 'https://yig-game.oss-cn-hangzhou.aliyuncs.com/BAT/new_xitunbao/love_self/%E7%94%B5%E5%99%A8%402x.gif' },
			],
		};
	},
	// 组件的方法
	methods: {

	},
	// 在created钩子中执行的操作
	mounted() {

	},
	deactivated() {

	}
};
</script>

<!-- 组件的样式 -->
<style scoped></style>